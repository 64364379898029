import React from "react";
import SEO from "../../components/common/seo.js";
import Layout from "../../components/common/layout";
import Navigation from "../../components/navigation/navigation";
import Header from "../../components/sections/header";
import Map from "../../components/sections/map";
import Contact from "../../components/sections/contact";
import { Dot, Container } from "../../components/global";
import styled from "styled-components";

const PrawoSpolek = () => (
  <Layout>
    <SEO
      title='Prawo Spółek'
      description='Kompleksowa obsługa spółek na każdym jej etapie, a zatem od powstania,
          funkcjonowania, aż do ustania bytu prawnego'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>prawo spółek</Title>

      <Container>
        <p>
          Kompleksowa obsługa spółek na każdym jej etapie, a zatem od powstania,
          funkcjonowania, aż do ustania bytu prawnego:
        </p>
        <List>
          <Dot>
            tworzenie oraz zakładanie spółek, ze szczególnym uwzględnieniem
            kwestii podatkowych;
          </Dot>
          <Dot>likwidacja spółek;</Dot>
          <Dot>postępowania restrukturyzacyjne i upadłościowe;</Dot>
          <Dot>
            bieżące doradztwo prawne oraz przygotowywanie dokumentacji
            korporacyjnej, w tym obsługa posiedzeń zarządów oraz rad
            nadzorczych;
          </Dot>
          <Dot>umowy wspólników/ akcjonariuszy;</Dot>
          <Dot>
            podwyższenie (emisja akcji/udziałow) i obniżenie kapitału
            zakładowego;
          </Dot>
          <Dot>emisja obligacji;</Dot>
          <Dot>umowy holdingowe dla grup kapitałowych;</Dot>
          <Dot>
            restrukturyzacja spółek, w tym tzw. squezee out oraz reverse squezee
            out;
          </Dot>
          <Dot>
            wynagradzanie członków zarządów oraz kadry kierowniczej (programy
            motywacyjne – opcje menadżerskie), w tym dla osób objętych tzw.
            ustawą kominową lub tzw. ustawą antykorupcyjną;
          </Dot>
          <Dot>
            ubezpieczenia D&O (Directors & Officers) oraz POSI (Public Offering
            of Securities Insurance).
          </Dot>
        </List>
      </Container>
    </Article>

    {/* <Contact /> */}
  </Layout>
);

export default PrawoSpolek;

const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  padding: 40px 0px 150px;
  li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.5;
  }
`;
const Article = styled.article`
  padding-top: 20px;
  /* border: 1px solid red; */
`;
